import React from 'react'
import Link from 'gatsby-link'
import { Jumbotron, Grid, Row, Col, Image, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'

class Footer extends React.Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
      <div>
        <Row className="show-grid">
          <Col xs={12}>
            <div className="footer">
              <img src="/img/profile-pic.png" />
              <span className="footer-name">Richard England-Lee</span>
              <span className="footer-title">Digital Designer</span>
            </div>
          </Col>
        </Row>

      </div>
    )
  }
}

export default Footer
