import React from "react";
import Link from "gatsby-link";
import {
  Jumbotron,
  Grid,
  Row,
  Col,
  Image,
  Button,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Container
} from "react-bootstrap";

class UpNext extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="up-next">
        <Container>
          <Row className="show-grid">
            <Col xs={12}>
              <div className="dividing-line">
                <h2>Up Next</h2>
              </div>
            </Col>
          </Row>
          <Row className="show-grid">
            <Col xs={12} sm={12} lg={4}>
              <div className="next-job">
                <a href={this.props.url1}>
                  <img src={this.props.image1} />
                  <h3>{this.props.title1}</h3>
                  <h4>{this.props.text1}</h4>
                </a>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={4}>
              <div className="next-job">
                <a href={this.props.url2}>
                  <img src={this.props.image2} />
                  <h3>{this.props.title2}</h3>
                  <h4>{this.props.text2}</h4>
                </a>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={4}>
              <div className="next-job">
                <a href={this.props.url3}>
                  <img src={this.props.image3} />
                  <h3>{this.props.title3}</h3>
                  <h4>{this.props.text3}</h4>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UpNext;
